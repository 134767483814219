<template>
  <b-container fluid>
    <header class="masthead bg-primary text-white text-center">
      <div class="b-container d-flex align-items-center flex-column">
        <!-- Masthead Avatar Image-->
        <img
          class="masthead-avatar mt-0 mb-4"
          src="../assets/img/robot.png"
          alt="Robot"
        />
        <!-- Masthead Heading-->
        <h6 class="masthead-heading test-uppercase mb-0">
          Plataforma Web
          <p>de Inteligência Artificial</p>
        </h6>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-brain"></i></div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- Masthead Subheading-->
        <p class="pre-wrap masthead-subheading font-weight-light mb-5">
          Para contribuir para o ensino da IA e em sintonia com a iniciativa 
          <b-link class ="text-white" href="https://ai4k12.org/" target="_BLANK"><ins>AI4K12</ins></b-link> !
         </p>
      </div>
    </header>
    <section id="about" class="page-section bg-primary text-white mt-5 mb-0">
      <div class="b-container">
        <!-- About Section Heading-->
        <div class="text-center">
          <h4 class="page-section-heading d-inline-block text-white mt-1 mb-0">
            Acerca deste Projeto
          </h4>
        </div>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-brain"></i></div>
          <div class="divider-custom-line"></div>
        </div><br><br><br>
        <!-- About Section Content-->
        <div class="row">
          <div class="col-lg-4 ml-auto">
            <p class="pre-wrap lead" style="text-align:center">
              Este é um projeto com o apoio do Grupo de Robótica e Inteligência Artificial da Universidade dos Açores ( <b-link class ="text-white" href="https://gria.uac.pt" target="_BLANK"><ins>GRIA</ins></b-link> ),
              do Laboratório de Sistemas Inteligentes, Ciência e Engenharia (<b-link class ="text-white" href="https://sites.google.com/view/universidade-dos-aores/is2e?authuser=0" target="_BLANK"><ins> IS2E </ins></b-link>) e do repositório <b-link class ="text-white" href="http://re-mar.uac.pt" target="_BLANK"><ins>Re-Mar</ins></b-link>
              com o objetivo de contribuir para o ensino da Inteligência Artificial ( IA ) em meio escolar e em sintonia com a iniciativa mundial <a class="text-white" @click="AI4K12()"><b>AI4K12</b></a>
              e maioritariamente para os níveis de ensino do 3º ciclo e secundário.</p>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
          </div>
          <div class="col-lg-4 mr-auto">
            <p class="pre-wrap lead" style="text-align:center">
              É neste sentido que são disponibilizadas ferramentas, com recursos didáticos associados, de forma livre, prática e
              acessível e classificadas em cinco áreas ou ideias predominantes do ensino da IA, no <a class="text-white" @click="repositorio()"><b>Repositório.</b></a> De forma a receber a Newsletter e a ficarmos com o seu contacto, pode e deve efetuar o seu registo
               <a class="text-white" @click="registo()"><b>aqui.<br></b></a> Para qualquer questão relacionada com os dados fornecidos, ou outra, pode também contactar-nos diretamente <a class="text-white" @click="contato()"><b>aqui</b></a> </p>                         
              <p>
            </p>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer text-center">
      <div class="b-container>">
        <div class="row">
          <!-- Footer Location-->
          <div class="col-lg-7">
            <h4 class="mb-0">Localização</h4>
            <p class="pre-wrap lead mb-0">Universidade dos Açores - FCT (<b-link class ="text-white" href="https://gria.uac.pt" target="_BLANK"><ins>GRIA</ins></b-link>)</p>
            <p>Ponta Delgada</p>
          </div>
          <!-- Footer About Text-->
          <div class="col-lg-4">
            <h4 class="mb-0">Sobre Web <small>&</small> IA</h4>
            <p class="pre-wrap lead mb-0">
              Web <small>&</small> IA usa Bootstrap com licença MIT e Framework Vue.Js
              </p>
          </div>
        </div>
      </div>             
    </footer>
    <!-- Copyright Section-->
    <section class="copyright py-4 text-center text-white">
      <div class="b-container>">
        <small class="pre-wrap">Copyright © Web <small>&</small> IA 2021</small>
      </div>
    </section>
    <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes)-->
    <div class="scroll-to-top d-lg-none position-fixed">
      <a
        class="js-scroll-trigger d-block text-center text-white rounded"
        href="#page-top"
        ><i class="fa fa-chevron-up"></i
      ></a>
    </div>
  </b-container>
</template>

<script>
import router from "@/router/index";

export default {
  methods: {
    registo() {
      router.push({ name: "Registo" });
    },
    contato(){
      router.push({name: "Contato"});
    },
    repositorio(){
      router.push({name: "Repositorio"});
    },
    AI4K12(){
      router.push({name: "AI4K12"});
    }
  
  },
}
</script>
