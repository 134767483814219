import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import OktaVue,{LoginCallback} from '@okta/okta-vue';
import { OktaAuth } from '@okta/okta-auth-js';

//const callback_path = 'callback';

const config = {
  issuer: 'https://dev-94483832.okta.com',
  clientId:'0oa1ffu4idnXxDRnJ5d7',
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile']
  }

const oktaAuth = new OktaAuth(config);

Vue.use(VueRouter);
// Vue.use(OktaVue, {oktaAuth});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login/callback",
    name: "Callback",
    component:LoginCallback
  },  
  {
    path: "/registo",
    name: "Registo",
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/AI4K12",
    name: "AI4K12",
    component: () => import("@/views/AI4K12.vue"),
  },
  {
    path: "/repositorio",
    name: "Repositorio",
    component: () => import("@/views/Repository.vue"),
  },
  {
    path: "/acerca",
    name: "Acerca",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/tool",
    name: "Ver ferramentas",
    component: () => import("@/views/Tool.vue"), meta: {requiresAuth:true}
  },
  {
    path: "/rtool",
    name: "Registar ferramenta",
    component: () => import("@/views/rTool.vue"), meta: {requiresAuth:true}
  },
  {
    path: "/mtools",
    name: "Menu feramentas",
    component: () =>import("@/views/mtool.vue"),meta: {requiresAuth:true}
  },
  {
    path: "/noticias",
    name: "Noticias",
    component: () =>import("@/views/News.vue")
  },
  {
    path: "/contato",
    name: "Contato",
    component: () =>import("@/views/Contact.vue")
  },
    {
    path: "/menu",
    name: "Menu",
    component: () =>import("@/views/Menu.vue"), meta: {requiresAuth:true}
  },
  {
    path: "/login",
    name: "Login",
    component: () =>import("@/views/Login.vue")
  },
  {
    path: "/users",
    name: "Users",
    component: () =>import("@/views/Users.vue"), meta: {requiresAuth:true}
  },
  {
    path: "/gusers",
    name: "Gusers",
    component: () =>import("@/views/Gusers.vue"), meta: {requiresAuth:true}
  },    
   // Redirect to 404 page, if no match found
  {
    path: "*",
    component: () => import("@/views/Error404.vue"),
  },
]});

export default router;
