<template>
  <b-container>
    <div id="page-top">
      <b-navbar-nav id="mainNav" class="navbar navbar-expand-lg bg-secondary fixed-top">
        <!--div class="container mt-auto ml-5 mr-auto"--> 
           <div class="container"> 
          <img class="img-fluid" src="../assets/img/uac.png" style="width:129px;height:59px;"> 
          <div class="container">
          <div class="navbar-brand js-scroll-trigger">
          <b-nav-item to="/">WEB <small>&</small> IA </b-nav-item></div>                      
          <button class="navbar-toggler navbar-toggler-right  font-weight-bold  bg-primary text-white rounded"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation">Menu <i class="fas fa-bars"></i></button>
          <div id="navbarResponsive" class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto">
              <div class="nav-link py-0 px-0 px-lg-2 rounded js-scroll-trigger">
                  <b-nav-item to="/Registo">Registo</b-nav-item></div>                  
              <div class="nav-link py-0 px-0 px-lg-2 rounded js-scroll-trigger">
                  <b-nav-item to="/AI4K12">AI4K12</b-nav-item></div>
              <div class="nav-link py-0 px-0 px-lg-2 rounded js-scroll-trigger">
                <b-nav-item to="/repositorio">Repositório</b-nav-item></div>
              <div class="nav-link py-0 px-0 px-lg-2 rounded js-scroll-trigger">
                  <b-nav-item to="/acerca">Acerca</b-nav-item></div>
                  <div class="nav-link py-0 px-0 px-lg-2 rounded js-scroll-trigger">
                  <b-nav-item to="/noticias">Notícias</b-nav-item></div>
              <div class="nav-link py-0 px-0 px-lg-2 rounded js-scroll-trigger">
                  <b-nav-item to="/contato">Contactos</b-nav-item></div>                             
              </ul>
            </div>
          </div>
        </div>
      </b-navbar-nav>
    </div>
  </b-container>
</template>
<script>

export default {
  name: 'app',
  data () {
    return {
      activeUser: null
    }
  },
  async created () {
    await this.refreshActiveUser()
  },
  watch: {
    // everytime a route is changed refresh the activeUser
    '$route': 'refreshActiveUser'
  },
  methods: {
    async login () {
      this.$auth.signInWithRedirect()
    },
    async refreshActiveUser () {
      if (this.authState.isAuthenticated) {
        this.activeUser = await this.$auth.getUser()
      }
    },
    async logout () {
      await this.$auth.signOut()
      await this.refreshActiveUser()
      this.$router.push('/')
    }
  }
}
</script>
